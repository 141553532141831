
/* FORMULAIRE */
.form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.form .input_row .input_container {
  position: relative;
  flex: 1;
}
.form .input_row .input_container:last-child {
  text-align: right;
}
.form .input_row:not(:last-child) .input_container > * {
  position: relative;
  margin-bottom: 15px;
}
.form .input_row {
  display: flex;
  flex-direction: row;
}
.form .input_row .input_container:not(:last-child) {
  margin-right: 15px;
}
.form .input_row .input_container .input .tooltip {
  position: absolute;
  bottom: 100%;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 1px 6px;
  font-size: .8em;
  color: #fff;
  background-color: rgba(0,0,0,.9);
  pointer-events: none;
  transition: .2s;
  transform: translateY(5px);
  z-index: 100;
  opacity: 0;
}
.form .input_row .input_container .input input:focus ~ .tooltip {
  transform: translateY(0px) !important;
  opacity: 1 !important;
}
.form .input_row .input_container input[type=text],
.form .input_row .input_container input[type=password] {
  width: 100%;
  text-align: left;
  border-radius: 7px;
  padding: 12px 24px 12px 40px;
  border: 1px solid var(--color-background-0dp);
  background-color: var(--color-background-0dp)!important;
  transition: .1s;
  outline: none;
}
.form .input_row .input_container input[type=password] {
  padding-right: 50px;
}
.form .input_row .input_container input[type=password] {
  padding-right: 50px;
}
.form .input_row .input_container .input_password_with_score input[type=password] {
  padding-right: 70px;
}
.form .input_row .input_container input[type=text]::placeholder,
.form .input_row .input_container input[type=password]::placeholder {
  color: var(--color-text-light);
}
.form .input_row .input_container input[type=text]:focus,
.form .input_row .input_container input[type=password]:focus {
  border: 1px solid var(--color-success-o-50);
  box-shadow: 0 0 4px 0 var(--color-success-o-50);
}
.form .input_row .input_container .input.input_error input[type=text],
.form .input_row .input_container .input.input_error input[type=password] {
  border: 1px solid var(--color-error-solid-background);
  box-shadow: 0 0 4px 0 var(--color-error-solid-background);
}
/**/
.form .input_row .input_container button,
.form .input_row .input_container input[type=submit] {
  line-height: 44px;
  padding: 0px 30px;
  border-radius: 5em;
  color: var(--color-brand-solid-text);
  border: 1px solid var(--color-brand-solid-background);
  background-color: var(--color-brand-solid-background);
  font-weight: bold;
  transition: .1s;
  cursor: pointer;
}
.form .input_row .input_container button:hover,
.form .input_row .input_container input[type=submit]:hover {
  border: 1px solid var(--color-brand);
  background-color: var(--color-brand);
}
.form .input_row .input_container button.submit_disabled,
.form .input_row .input_container input[type=submit].submit_disabled {
  font-weight: normal;
  color: var(--color-brand);
  border: 1px solid var(--color-brand-faded-background);
  background-color: var(--color-brand-faded-background);
  /*color: var(--color-text-light);
  border: 1px solid var(--color-background-0dp);
  background-color: var(--color-background-0dp);*/
  cursor: default;
}
.form .input_row .input_container .input_hidden {
  margin: 0px;
}
/* ALERTS */
.form .input_row .input_container .container_alerts {
  text-align: left;
}
.form .input_row .input_container .container_alerts ._alert {
  transition: .2s;
}
.form .input_row .input_container .container_alerts ._alert svg {
  position: relative;
  height: 16px;
  width: 16px;
  top: 2px;
  margin-right: 3px;
}
.form .input_row .input_container .container_alerts ._alert i {
  position: relative;
  margin-right: 3px;
}
.form .input_row .input_container .container_alerts ._alert > span {
  position: relative;
  transition: inherit;
}
.form .input_row .input_container .container_alerts ._alert > span /deep/ a {
  color: inherit;
}
.form .input_row .input_container .container_alerts ._alert > span /deep/ a:hover {
  text-decoration: underline;
}
.form .input_row .input_container .container_alerts ._alert._alert_error > span:before {
  position: absolute;
  content: ' ';
  width: 100%;
  height: 1px;
  top: 50%;
  transition: .7s;
  transform: scaleX(0);
  transform-origin: left;
  background-color: var(--color-text);
}
.form .input_row .input_container .container_alerts ._alert._alert_error svg {
  fill: var(--color-warn);
}
.form .input_row .input_container .container_alerts ._alert._alert_error svg.icon_warning path:last-child {
  fill: currentColor;
}
.form .input_row .input_container .container_alerts ._alert._alert_message {
  color: var(--color-neutral-faded-text);
}
.form .input_row .input_container .container_alerts ._alert._alert_message svg {
  fill: currentColor;
}
.form .input_row .input_container .container_alerts ._alert._alert_error {
  /*color: var(--color-warn-solid-background-d);*/
  color: var(--color-error-solid-background);
}
.form .input_row .input_container .container_alerts ._alert._alert_corrected {
  color: var(--color-text-light);
}
.form .input_row .input_container .container_alerts ._alert._alert_corrected svg {
  fill: #27C469;
}
.form .input_row .input_container .container_alerts ._alert._alert_corrected > span:before  {
  transform: scaleX(1);
}
/**/
.form .input_row .input_container .link,
.form .input_row .input_container .checkbox {
  font-size: .93333em;
  color: var(--color-text-light);
}
.form .input_row .input_container .checkbox label {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
}
.form .input_row .input_container .link div {
  position: relative;
  display: inline-block;
  transition: .1s;
  cursor: pointer;
}
.form .input_row .input_container .link div:before {
  position: absolute;
  content: ' ';
  height: 1px;
  width: 100%;
  left: 0px;
  top: 100%;
  opacity: .7;
  margin-top: -1px;
  background-color: currentColor;
  transform-origin: left;
  transform: scaleX(0);
  transition: inherit;
  opacity: 0;
}
.form .input_row .input_container .link div:hover:before {
  transform: scaleX(1);
  opacity: 1;
}
.form .input_row .input_container .icon {
  position: absolute;
  display: flex;
  height: 100%;
  width: 40px;
  left: 0px;
  top: 0px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  fill: currentColor;
}
.form .input_row .input_container input:focus ~ .icon {
  font-weight: bold;
}
.form .input_row .input_container .icon_show_password {
  position: absolute;
  display: flex;
  height: 100%;
  width: 40px;
  right: 0px;
  top: 0px;
  align-items: center;
  justify-content: center;
  color: var(--color-text-light);
  transition: .1s;
  cursor: pointer;
}
.form .input_row .input_container input:focus ~ .icon_show_password {
  color: var(--color-text);
}
.form .input_row .input_container .icon_show_password i {
  font-size: .8666667em;
  font-weight: bold;
}
.form .input_row .input_container .score_password {
  position: absolute;
  display: flex;
  height: 96%;
  width: 20px;
  top: 0px;
  right: 40px;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.form .input_row .input_container .score_password svg {
  height: 14px;
  width: 14px;
}
.form .input_row .input_container .score_password svg .bg_score { opacity: .7; }
.form .input_row .input_container .score_password_0 svg .bg_score,
.form .input_row .input_container .score_password_1 svg .bg_score { fill: red; }
.form .input_row .input_container .score_password_2 svg .bg_score { fill: orange; }
.form .input_row .input_container .score_password_3 svg .bg_score,
.form .input_row .input_container .score_password_4 svg .bg_score { fill: var(--color-success); }

.form .input_row .input_container .google_connect {
  display: flex;
  height: 40px;
  color: #fff;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  border: 1px solid rgb(80, 137, 232);
  background-color: rgb(80, 137, 232);
  font-weight: bold;
  text-decoration: none;
  transition: .1s;
  cursor: pointer;
  outline: none;
}
.form .input_row .input_container .google_connect:hover {
  border: 1px solid rgb(75, 127, 213);
  background-color: rgb(75, 127, 213);
}
.form .input_row .input_container .google_connect .logo {
  display: flex;
  height: 100%;
  width: 40px;
  border-radius: 2px;
  background-color: var(--color-background-24dp);
  justify-content: center;
  align-items: center;
}
.form .input_row .input_container .google_connect .logo img {
  height: 20px;
  width: 20px;
}
.form .input_row .input_container .google_connect .content {
  text-align: center;
  flex: 1;
}
.form .input_row .input_container .separator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--color-text-light);
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  cursor: default;
}
.form .input_row .input_container .separator:after,
.form .input_row .input_container .separator:before {
  content: ' ';
  height: 1px;
  flex: 1;
  opacity: .5;
  background-color: var(--color-border);
}
.form .input_row .input_container .separator div {
  margin: 0 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--color-text) !important;
  color: var(--color-text) !important;
  background-color: var(--color-background-0dp) !important;
  -webkit-box-shadow: 0 0 0 1000px var(--color-background-0dp) inset !important;
}

/*input:-internal-autofill-selected {
  appearance: menulist-button;
}*/

input:-webkit-autofill {
  animation-delay: .6s;
  animation-name: onautofillstart;
}
input:not(:-webkit-autofill) {
  animation-name: onautofillcancel;
}
@keyframes onautofillstart {
  0% { opacity: .999999; }
  100% { opacity: 1; }
}
@keyframes onautofillcancel {
  0% { opacity: .999999; }
  100% { opacity: 1; }
}
