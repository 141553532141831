
<template>
  <div class="_container">
    <div class="bg_shape">
      <svg class="shape" viewBox="0 0 327.01 331"><path d="M97.52,328.2C12.26,311-41.32,70.63,40.85,22.2,180.27-60,322.22,104.92,327,210.59,330.09,281,182.79,345.35,97.52,328.2Z" /></svg>
    </div>

    <div class="sign_in_up" @click="goToPage(linkSignUp.route)" v-html="linkSignUp.content" />

    <div class="_content">
      <div class="title" v-html="title" />

      <Decor class="illustration" />

      <div class="links">
        <div class="link" @click="goToPage('/signin')" @mouseover="overLink($event)" @mouseout="outLink($event)">
          {{ signin }}
        </div>

        <div class="link" @click="goToPage('/signup')" @mouseover="overLink($event)" @mouseout="outLink($event)">
          {{ signup }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Decor from '@/feature/sign_in/components/decor/Decor'

export default {
  name: 'Error404',
  components: {
    Decor
  },
  data () {
    return {
      linkSignUp: {
        content: '<span>' + window.I18n.t('login.shared.have_account') + '</span> <span>' + window.I18n.t('login.shared.title') + '</span>',
        route: '/signin'
      },
      title: window.I18n.t('login.shared.page_not_found'),
      signin: window.I18n.t('login.shared.title'),
      signup: window.I18n.t('register.title')
    }
  },
  methods: {
    overLink (event) {
      event.currentTarget.style['transform-origin'] = event.layerX + 'px'
    },
    outLink (event) {
      event.currentTarget.style['transform-origin'] = (event.layerX - event.currentTarget.offsetLeft) + 'px'
    },
    goToPage (route) {
      this.$router.push(route)
    }
  }
}

</script>

<style scoped src="@/feature/sign_in/assets/css/page.css"></style>

<style scoped>

._container ._content .title {
  margin-bottom: 50px;
}
._container ._content .links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}
._container ._content .links .link {
  position: relative;
  margin: 20px;
  font-weight: bold;
  padding-bottom: 1px;
  transition: transform .18s .02s, transform-origin .1s;
  overflow: hidden;
  cursor: pointer;
}
._container ._content .links .link:before {
  position: absolute;
  content: ' ';
  width: 100%;
  height: 1px;
  left: 0px;
  top: 100%;
  margin-top: -1px;
  transition: inherit;
  transform-origin: inherit;
  background-color: currentColor;
  transform: scaleX(0);
}
._container ._content .links .link:hover:before {
  transform: scaleX(1);
}

</style>
