
<template>
  <div class="_container">
    <div class="bg_shape">
      <svg class="shape" viewBox="0 0 327.01 331"><path d="M97.52,328.2C12.26,311-41.32,70.63,40.85,22.2,180.27-60,322.22,104.92,327,210.59,330.09,281,182.79,345.35,97.52,328.2Z" /></svg>
      <svg class="shape" viewBox="0 0 327.01 331"><path d="M97.52,328.2C12.26,311-41.32,70.63,40.85,22.2,180.27-60,322.22,104.92,327,210.59,330.09,281,182.79,345.35,97.52,328.2Z" /></svg>
    </div>

    <div class="sign_in_up" @click="goToPage(linkSignUp.route)" v-html="linkSignUp.content" />

    <div class="_content">
      <div class="title" v-html="title" />

      <Decor class="illustration" />

      <Form :data="form" :show-tooltip="true" />
    </div>
  </div>
</template>

<script>

import Decor from '@/feature/sign_in/components/decor/Decor'
import Form from '@/feature/sign_in/components/utils/form/Form'
import { getCsrfToken } from '@/feature/sign_in/helpers'

const zxcvbn = require('zxcvbn')

export default {
  name: 'PasswordEdit',
  components: {
    Form,
    Decor
  },
  props: {
    email: {
      required: false,
      type: String,
      default: ''
    }
  },
  data () {
    return {
      linkSignUp: {
        content: '<span>' + window.I18n.t('login.shared.not_have_account') + '</span> <span>' + window.I18n.t('register.title') + '</span>',
        route: '/signup'
      },
      title: window.I18n.t('login.shared.reset_your_password').replace(/\n/g, '<br/>'),
      form: {
        // submit: this.submit,
        action: '/users/password',
        method: 'POST',
        inputs: [
          {
            type: 'password',
            name: 'user[password]',
            icon: 'fal fa-lock-alt',
            autocomplete: 'new-password',
            placeholder: window.I18n.t('collaborators.edit.new_password'),
            onInput: this.updateScorePassword,
            hidePassword: true,
            score: 0,
            value: '',
            controller: {
              error: false,
              list: [
                [(input) => { return input.value.trim() === '' }, window.I18n.t('register.validations.required', { input: window.I18n.t('register.password') })],
                [(input) => { return input.score < 3 }, window.I18n.t('register.validations.password_too_weak_simple')]
              ]
            }
          },
          {
            type: 'password',
            name: 'user[password_confirmation]',
            icon: 'fal fa-lock-alt',
            autocomplete: 'new-password',
            placeholder: window.I18n.t('collaborators.edit.new_password_confirmation'),
            onInput: this.isConfirmPassword,
            isConfirmPassword: false,
            hidePassword: true,
            value: '',
            controller: {
              error: false,
              list: [
                [(input) => { return input.value.trim() === '' }, window.I18n.t('register.validations.required', { input: window.I18n.t('register.password') })],
                [(input) => { return !input.isConfirmPassword }, window.I18n.t('register.validations.password_did_not_match')]
              ]
            }
          },
          {
            type: 'error',
            name: 'alert',
            value: { errors: this.$appParams.flashAlert ? { flashAlert: { message: this.$appParams.flashAlert } } : {}, messages: {} }
          },
          [
            {
              type: 'link',
              content: window.I18n.t('login.shared.title'),
              style: { 'margin-top': '18px' },
              route: 'signin'
            },
            {
              type: 'submit',
              content: window.I18n.t('login.shared.reset_pwd'),
              style: { 'white-space': 'nowrap', 'margin-bottom': '48px', 'margin-top': '10px' }
            }
          ],
          [
            {
              type: 'hidden',
              name: 'user[reset_password_token]',
              value: this.$route.query.reset_password_token ? this.$route.query.reset_password_token : ''
            },
            {
              type: 'hidden',
              name: 'authenticity_token',
              value: getCsrfToken()
            },
            {
              type: 'hidden',
              name: 'utf8',
              value: '✓'
            },
            {
              type: 'hidden',
              name: '_method',
              value: 'put'
            },
            {
              type: 'hidden',
              name: 'request_type',
              value: this.$appParams.submitJs ? 'js' : ''
            }
          ]
        ]
        // autofocus: 'user[password]'
      }
    }
  },
  methods: {
    goToPage (route) {
      this.$router.push(route)
    },
    isConfirmPassword (input) {
      const temp = this.form.inputs.find(input => input.name === 'user[password]')
      input.isConfirmPassword = input.value !== '' && temp.value === input.value
    },
    updateScorePassword (input) {
      const score = zxcvbn(input.value)
      if (score.score !== undefined) input.score = score.score
    }
  }
}

</script>

<style scoped src="@/feature/sign_in/assets/css/page.css"></style>

<style scoped>

._container ._content .title {
  text-align: left;
  margin-bottom: 30px;
}

._container ._content .illustration {
  margin-bottom: 10px;
}

</style>
